
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BrandContact {
  padding: spacing(4) 0 spacing(3);
  color: $color-black-primary;

  input,
  select {
    height: 56px;
  }

  @include mq($breakpoint-desktop) {
    text-align: center;
    padding: spacing(10) 0 spacing(10.5);
  }
}

.Title {
  margin-bottom: spacing(2);
  @include mq($breakpoint-desktop) {
    justify-content: center;
    margin-bottom: spacing(4.5);
  }
}

.Description {
  margin: 0 auto spacing(4);
  max-width: 900px;
  @include mq($breakpoint-desktop) {
    margin: 0 auto spacing(5.5);
  }
}

.FieldWrapper {
  margin-bottom: spacing(3);
  text-align: left;
}

.FieldTitle {
  text-align: left;
}

.Captcha {
  display: flex;
  justify-content: center;
  padding: spacing(0.5) 0;
  margin: spacing(0.5) 0 spacing(3.5);
  overflow: hidden;
}

.FauxSelect {
  display: none;
}
.Select {
  height: 56px;
  box-shadow: 0 0 0 1px inset $color-black-primary,
    0 0 0 1px $color-black-primary;
  border: 0 !important;
  border-radius: 0 !important;

  > div {
    align-items: center;
    padding-left: 8px;
    font-size: 16px;
  }

  > div:nth-child(2) {
    svg {
      transform-origin: center center;
      fill: $color-grey-secondary;
      transform: scale(1.3) translateY(2px);

      [aria-expanded='true'] & {
        transform: scale(1.3) translateY(2px) translateX(3px);
      }
    }
  }

  &:hover,
  &[aria-expanded='true'] {
    box-shadow: 0 0 0 1px inset var(--color-theme--secondary),
      0 0 0 1px var(--color-theme--secondary) !important;
  }

  &[aria-expanded='true'] {
    > div:nth-child(2) {
      svg {
        transform: scale(1.3) translateY(2px) translateX(-4px);
      }
    }
    > div:last-child {
      background: $color-black-primary;
      margin-top: -6px;
      padding-left: 0;
      box-shadow: 0 4px 28px rgb($color-black, 0.5);
      border: 0;
      border-radius: 0;
      align-items: flex-start;

      span {
        width: 100%;
        font-size: 16px;
        color: $color-white-primary;
        padding: 13px 6px 9px 12px;
        border-bottom: 1px solid $color-misc-divider;

        &:hover,
        &[aria-selected='true'] {
          background-color: var(--color-theme--secondary);
        }

        @include mq($breakpoint-desktop) {
          font-size: 14px;
        }
      }

      @include mq($breakpoint-desktop) {
        width: 50%;
      }
    }
  }
}

.Submit {
  width: 100%;
  padding: spacing(2) spacing(3);
  @include mq($breakpoint-desktop) {
    max-width: 190px;
  }
}

/** New CSS **/

.Formcrafts {
  min-height: 950px; // 950px is the height of the mobile form as of Dec 2024

  @include mq($breakpoint-tablet) {
    min-height: 570px; // 570px is the height of the desktop form as of Dec 2024
  }

  > iframe {
    width: 100% !important;
  }
}
