
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.WidgetArea {
  height: 100%;
  &.capped-width {
    width: 100%;
    @include mq($breakpoint-desktop) {
      max-width: 668px;
    }
  }
}
