
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.IframeCarouselContainer {
  position: relative;
  width: 90%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.IframeCarousel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.CarouselPagination {
  @include mq($breakpoint-desktop) {
    top: 50% !important;
  }
}
