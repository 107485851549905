
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ArticleHeader1 {
  & > * + * {
    margin-top: spacing(2);

    @include mq($breakpoint-desktop) {
      margin-top: spacing(1);
    }
  }

  .Details {
    color: $color-grey-primary;
    display: flex;
    flex-direction: column;
    gap: spacing(2);

    @include mq($breakpoint-tablet) {
      flex-direction: row;
      gap: spacing(6);
    }

    .DetailsContent > * + * {
      margin-top: spacing(1);
    }
  }
}

.ArticleHeader2 {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  .ShortHeadline {
    color: $color-grey-secondary;
    &.themed {
      color: var(--color-theme--secondary);
    }
  }

  .Image,
  .Headline,
  .ShortHeadline,
  .Company {
    margin-top: spacing(2);
  }

  .Company {
    max-width: 78px;
  }

  .Image {
    flex-shrink: 0;
  }

  .ButtonWrapper {
    margin-top: spacing(2);
    @include mq($breakpoint-tablet) {
      position: absolute;
      bottom: 0;
      margin-top: 0;
    }
  }

  .Button {
    @include mq($max: $breakpoint-desktop - 1) {
      width: 100%;
    }
  }

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    align-items: center;

    .Image {
      margin-right: spacing(4.5);
      margin-top: 0;
      max-width: 322px;
    }

    .Headline {
      margin-top: spacing(2.5);
    }

    .ShortHeadline {
      margin-top: spacing(1.5);
    }

    .Company {
      margin-top: spacing(4);
      max-width: 128px;
    }
  }

  @include mq($breakpoint-desktop) {
    .Image {
      margin-right: spacing(8.5);
    }

    .Headline {
      margin-top: spacing(4.5);
    }

    .Company {
      margin-top: spacing(4);
    }
  }
}

.ArticleHeader3 {
  & > * + * {
    margin-top: spacing(4);
  }

  @include mq($breakpoint-tablet) {
    & > * + * {
      margin-top: spacing(3);
    }
  }
}

.Author {
  color: $color-black-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-theme--secondary);
  }
}
