
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ListItem {
  margin-bottom: spacing(4);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(7);
  }

  h5 {
    margin-bottom: soacing(2);
    @include mq($breakpoint-desktop) {
      margin-bottom: spacing(3);
    }
  }
}

.Button {
  @include mq($max: $breakpoint-desktop - 1) {
    width: 100%;
  }
}
