
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContactConfirmation {
  text-align: center;
  padding: spacing(3) spacing(2);

  @include mq($breakpoint-desktop) {
    padding: spacing(16) 0;
  }
}

.Icon {
  margin-bottom: spacing(8);

  @include mq($max: $breakpoint-desktop - 1) {
    margin-bottom: spacing(2);
    width: 64px !important;
    height: 57px !important;
    line-height: 57px !important;
  }

  svg path {
    fill: $color-misc-green;
  }
}

.Title {
  margin-bottom: spacing(1.5);
  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2);
  }
}
