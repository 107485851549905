
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Wrapper {
  text-decoration: none;
}

.MagazineCover {
  box-shadow: 0 4px 24px rgba($color-black, 0.4);
}

.Image {
  margin-bottom: spacing(4);

  a:hover &,
  a:focus & {
    //opacity: 0.8;
  }
}

.Text {
  color: $color-black-primary;
  text-decoration: none;
}

.muted {
  color: $color-grey-secondary;
}
