
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Tag {
  padding: 0 6px;
  display: inline-block;
  background-color: $color-white-primary;
  border: 1px solid $color-misc-divider;
  color: $color-black-secondary;
  text-decoration: none;
  margin-right: spacing(0.5);

  @include mq($breakpoint-desktop) {
    line-height: 1;
  }
}

a.Tag:hover,
a.Tag:focus {
  background-color: $color-grey-secondary;
}

.transparent {
  background-color: transparent;
}

.white {
  background-color: $color-white-primary;
}

.black {
  background-color: $color-black-primary;
  color: $color-white-primary;
  border: 1px solid $color-white-primary;
}

.grey {
  background-color: $color-grey-primary;
  color: $color-white-primary;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}

.themed {
  color: var(--color-theme--secondary);
}
