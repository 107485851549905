
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MagazineIssueHighlights {
  background-color: $color-black-primary;
  padding: spacing(0.375) 0 spacing(1.375);
}

.Title {
  margin-bottom: spacing(3);
  padding-bottom: spacing(1.5);
}

.HighlightTitle {
  margin-bottom: spacing(1);
}

.Highlight {
  display: flex;
  text-align: left;
  align-items: stretch;
  color: $color-white-primary;
  img {
    margin-right: spacing(2);
  }

  @include mq(null, $breakpoint-tablet) {
    padding-bottom: spacing(3);
    position: relative;
    width: 100%;

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-white-secondary;
      opacity: 0.2;
      left: 0;
      bottom: 0;
    }
  }
}

.HighlightContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Page {
  display: inline-block;
  margin-top: auto;
  border: 1px solid $color-misc-divider;
  padding: spacing(0.625) spacing(0.75);
}
