
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.OffsetAnchor {
  display: block;
  visibility: hidden;
  height: 20px;
  margin-top: -20px;

  @include mq($breakpoint-desktop) {
    height: 160px;
    margin-top: -160px;
  }
}
