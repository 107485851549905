
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ArticleEventRegistration {
  color: $color-white-primary;
  background-color: var(--color-theme--secondary);

  &:hover,
  &:focus {
    svg path {
      fill: $color-white-primary;
    }
  }
}
