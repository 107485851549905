
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BrandFeatures {
  display: flex;
  justify-content: space-around;
  background-color: $color-black-secondary;
  padding-top: spacing(3);
  padding-bottom: spacing(3);
}

.Feature {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .FeatureImage {
    max-width: 48px;
    margin-bottom: spacing(1);
    margin-left: spacing(5);
    margin-right: spacing(5);

    @include mq($breakpoint-tablet) {
      max-width: 92px;
      margin-bottom: spacing(2);
    }
  }

  .FeatureLabel {
    justify-content: center;
    color: $color-white-primary;
  }
}
