
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SignUp {
  background: $color-black-primary;
  color: $color-white-primary;
  padding: spacing(5) 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    border-top: 3px solid var(--color-theme--secondary);
  }

  @include mq($breakpoint-desktop) {
    padding: spacing(7) 0;

    &::before {
      border-width: 4px;
    }
  }
}

.SignUpInner {
  position: relative;
  z-index: 1;
  @include mq($breakpoint-desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.Content {
  margin-right: spacing(4);

  @include mq($max: $breakpoint-desktop - 1) {
    max-width: 300px;
    margin: 0 auto;
  }
}

.InnerContent {
  @include mq($breakpoint-desktop) {
    max-width: 320px;
  }
}

.List {
  list-style-type: none;
  margin: spacing(4) 0;
  li {
    margin-bottom: spacing(1);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ListItem {
  padding-left: spacing(2);

  @include mq($breakpoint-desktop) {
    padding-left: spacing(4);
  }
}

.SubscribedMessage {
  margin: spacing(5) 0;
}

.SubscribedButton {
  max-width: 340px;
  width: 100%;
}

.Submit {
  margin-top: spacing(4);
  padding: spacing(2.5) spacing(5);
}

.Texture {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
