
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.RankedList {
  padding: spacing(0.5) spacing(2.5);
  background-color: $color-white-secondary;

  & li:last-child {
    .RankedListItem,
    .RankedListLink {
      border: none;
    }
  }

  @include mq($max: $breakpoint-desktop - 1) {
    margin: 0 spacing(-2);
  }
}

.RankedListTitle {
  padding: spacing(2) 0;
  border-bottom: 2px solid $color-misc-divider;
  text-transform: uppercase;
}

.RankedListTitleSecondary {
  color: $color-grey-secondary;
}

.RankedListItem {
  border-bottom: 1px solid $color-misc-divider;
  padding: spacing(2) 0;
}

.RankedListLink {
  text-decoration: none;
  padding: spacing(2) 0;
  color: $color-black-primary;
  display: block;
  border-bottom: 1px solid $color-misc-divider;

  &:hover,
  &:focus {
    color: var(--color-theme--secondary);
  }
}
