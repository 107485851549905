
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Link {
  display: block;
  margin-bottom: spacing(1);
  text-align: left;
  color: $color-white-primary;
  text-decoration: none;
  padding: spacing(2.25) spacing(2.5);
  background-color: $color-black-secondary;

  &.linkedin {
    background-color: $color-misc-linkedin;

    &:hover,
    &:focus {
      background-color: darken($color-misc-linkedin, 0.1);
    }
  }

  &.twitter {
    background-color: $color-misc-twitter;
  }

  @include mq($breakpoint-desktop) {
    display: inline-block;
    width: 100%;
    max-width: 300px;
    padding: spacing(1.5) spacing(2.5);
    margin-bottom: 0;
  }

  path {
    fill: $color-white-primary;
  }
}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: spacing(4);
  height: spacing(4);
}
