
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EntitiesContainer {
  border-bottom: 2px solid $color-misc-divider;
  padding-bottom: spacing(3);

  & + & {
    margin-top: spacing(2);
  }

  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(1);
  }
}

.Entity {
  text-decoration: none;
  color: $color-black-primary;
  margin-bottom: spacing(3);
  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    color: var(--color-theme--secondary);
  }
}

.NoLink {
  &:hover,
  &:focus {
    color: $color-black-primary;
  }
}

.Title {
  color: $color-misc-red;
  margin-bottom: spacing(3);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(1);
  }
}

.Name {
  display: inline-block;
  line-height: 1;
}

.Icon {
  display: inline-block;
  margin-left: spacing(2);
  margin-bottom: 3px;
}
