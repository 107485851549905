
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BasicImage {
  margin-left: spacing(-2);
  margin-right: spacing(-2);

  img {
    width: 100%;
  }

  .Caption {
    padding: spacing(1.5) spacing(2);
    background-color: $color-misc-caption;
    color: $color-grey-primary;
    @include mq($breakpoint-desktop) {
      padding: spacing(2) 0 0 0;
      background-color: transparent;
    }
  }

  @include mq($breakpoint-desktop) {
    margin: 0;
  }
}

.InterviewImages {
  display: none;

  @include mq($breakpoint-tablet) {
    display: block;
  }
}
