
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EditorialContactForm {
  & > .CustomField + .CustomField {
    margin-top: spacing(5);
    position: relative;
  }

  input,
  textarea {
    border: 0;
    border-bottom: 2px solid $color-misc-grey;
    padding: 0 0 spacing(2) 0;
    color: $color-black-primary;
  }

  .CustomField {
    position: relative;
  }

  input,
  textarea,
  .Placeholder,
  .Placeholder sup {
    font-size: $font-body-20;
    font-weight: $font-weight-medium;
    line-height: 1;
  }

  .Placeholder {
    position: absolute;
    left: 0;
    top: 0;
    transition: $duration-shortest $easing-standard;
    color: $color-misc-divider;
    pointer-events: none;

    sup {
      top: 0;
      color: $color-misc-red;
    }

    &.hidden {
      animation: hide $duration-short $easing-standard forwards;
    }
  }

  @include mq($breakpoint-tabletLandscape) {
    & > .CustomField + .CustomField {
      margin-top: spacing(9.5);
    }

    input,
    textarea {
      padding: 0 0 spacing(3.5) 0;
    }

    input,
    textarea,
    .Placeholder,
    .Placeholder sup {
      font-size: $font-heading-two;
    }
  }
}

.Captcha {
  display: flex;
  padding: spacing(4) 0;
  overflow: hidden;
  @include mq($breakpoint-tabletLandscape) {
    padding: spacing(7) 0 spacing(6);
  }
}

.Submit {
  width: 100%;
  padding: spacing(2) spacing(3);
  @include mq($breakpoint-tabletLandscape) {
    max-width: 183px;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  99% {
    display: none;
  }

  100% {
    opacity: 0;
  }
}
