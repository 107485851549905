
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.RankedListTags {
  margin-bottom: spacing(1);
  > div:first-child {
    color: var(--color-theme--secondary);
  }
}
