
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .CardGrid

.CardGrid7 {
  color: $color-white-primary;
  background-color: $color-black-primary;
  border: spacing(1) solid $color-black-primary;

  @include mq($breakpoint-desktop) {
    border: spacing(2) solid $color-black-primary;
  }

  .CardContentRoot {
    background-color: $color-black-primary;
    padding: spacing(2) spacing(1) spacing(1) spacing(1);
    @include mq($breakpoint-desktop) {
      padding: spacing(4) spacing(2) spacing(2) spacing(2);
    }

    .Description {
      color: rgba($color-white-primary, 0.6);
    }
  }

  .CardImageRoot {
    margin-bottom: 0;
    border: 2px solid $color-black-primary;
    outline-offset: 10px;

    &::before {
      box-shadow: none;
    }
  }
}
