
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MagazineLatestCTA {
  --magazine-latest-cta-grey: #efefef;

  /* stylelint-disable */
  container-name: magazinelatestctacontainer;
  container-type: inline-size;
  /* stylelint-enable */
  position: relative;
}

/* stylelint-disable */
@container magazinelatestctacontainer (width > 768px) {
  .MagazineLatestCTAInner {
    align-items: center;
    background-color: var(--magazine-latest-cta-grey);
    display: grid;
    grid-template-columns: 296px 1fr;
    padding: 20px;
  }
}
/* stylelint-enable */

.MagazineLatestCTAImageHolder {
  /* stylelint-disable */
  aspect-ratio: 296/233;
  /* stylelint-enable */

  margin-inline: 20px;
  perspective: 114px;
  position: relative;
  z-index: 2;

  img {
    animation-duration: 6s;
    animation-iteration-count: infinite;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 6px, rgba(0, 0, 0, 0.32) 0 3px 6px;
    height: auto;
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    width: 40%;

    &:nth-child(1) {
      animation-name: magazine-one;
      left: 50%;
      transform: translateX(-50%) translateZ(32px);
      z-index: 5;
    }

    &:nth-child(2) {
      animation-name: magazine-two;
      left: 0;
      transform: translateX(0) translateZ(0);
      z-index: 0;
    }

    &:nth-child(3) {
      animation-name: magazine-three;
      left: 100%;
      transform: translateX(-100%) translateZ(0);
      z-index: 0;
    }
  }
}

.MagazineLatestCTALink {
  align-items: start;
  background-color: var(--magazine-latest-cta-grey);
  color: $color-black-primary;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  gap: 0.75em;
  justify-content: center;
  margin-top: -3em;
  padding: 4em 1em 1em;
  text-decoration: none;

  .MagazineLatestCTA:has(a:active, a:hover) & {
    text-decoration: underline;
    text-decoration-color: var(--color-theme--secondary);
    text-decoration-thickness: 0.1em;
    text-underline-offset: 0.33em;
  }

  &::before {
    content: '';
    inset: 0;
    position: absolute;
    z-index: 10;
  }

  svg {
    fill: var(--color-theme--secondary);
    height: auto;
    margin-top: 0.5em;
    width: 0.5em;
  }
}

@media (min-width: 640px) {
  .MagazineLatestCTALink {
    font-size: 20px;
  }
}

/* stylelint-disable */
@container magazinelatestctacontainer (width > 768px) {
  .MagazineLatestCTALink {
    padding: 0;
    margin-top: unset;
    font-size: clamp(20px, 3cqi, 40px);
  }
}
/* stylelint-enable */

@keyframes magazine-one {
  0% {
    left: 50%;
    transform: translateX(-50%) translateZ(32px);
  }
  32% {
    left: 0;
    transform: translateX(0) translateZ(0);
    z-index: 0;
  }
  64% {
    left: 100%;
    transform: translateX(-100%) translateZ(0);
    z-index: 0;
  }
  100% {
    left: 50%;
    transform: translateX(-50%) translateZ(32px);
  }
}

@keyframes magazine-two {
  0% {
    left: 0;
    transform: translateX(0) translateZ(0);
  }
  32% {
    left: 100%;
    transform: translateX(-100%) translateZ(0);
  }
  64% {
    left: 50%;
    transform: translateX(-50%) translateZ(32px);
    z-index: 5;
  }
  100% {
    left: 0;
    transform: translateX(0) translateZ(0);
  }
}

@keyframes magazine-three {
  0% {
    left: 100%;
    transform: translateX(-100%) translateZ(0);
  }
  32% {
    left: 50%;
    transform: translateX(-50%) translateZ(32px);
    z-index: 5;
  }
  64% {
    left: 0;
    transform: translateX(0) translateZ(0);
  }
  100% {
    left: 100%;
    transform: translateX(-100%) translateZ(0);
  }
}
