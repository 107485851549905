
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .ProfileHeader {}

.Image {
  // Because Apollo only feeds us the image of 200x200, we need to set the aspect ratio.
  width: 100px;
  height: 100px;
  margin-bottom: spacing(2);
  @include mq($breakpoint-desktop) {
    width: 60px;
    height: 60px;
  }

  &.desktopOnly {
    display: none;
    @include mq($breakpoint-desktop) {
      display: block;
    }
  }
}

.MainHeader {
  @include mq($breakpoint-desktop) {
    display: flex;
    flex-direction: row;
    gap: spacing(4);
    padding: spacing(4) 0 spacing(1) 0;
  }
}

.Heading {
  margin-bottom: spacing(2);
  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(1);
  }
}

.SubHeading {
  color: var(--color-theme--secondary);
  margin-bottom: spacing(2);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2.5);
  }
}

.Description {
  display: grid;
  gap: spacing(2);

  @include mq($breakpoint-desktop) {
    margin-top: spacing(2);
  }
}

.RightColumn {
  margin-top: spacing(2.5);
  display: grid;
  gap: spacing(1.5);
  position: relative;

  @include mq($breakpoint-desktop) {
    max-width: 300px;
    margin-left: auto;
  }
}

.Meta {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(2);
}

.MetaTable {
  @include mq($breakpoint-desktop) {
    max-width: 400px;
  }
}

.MetaList {
  display: grid;
  gap: spacing(2);
}

.MetaTitle {
  color: var(--color-theme--secondary);
}

.ProfileTagKeywords {
  background-color: $color-black-primary;
}

.TableLabel {
  display: inline-flex;
  align-items: flex-start;
  gap: spacing(0.5);
  flex-shrink: 0;

  &.highlight {
    align-items: flex-end;
  }
}

.TableItem {
  display: flex;
  padding: spacing(2) 0;
  gap: spacing(2);
  border-bottom: 1px solid rgba($color-black-primary, 0.2);

  &.title {
    color: var(--color-theme--secondary);
    padding-top: 0;
  }
}

.Title {
  color: var(--color-theme--secondary);
}
