
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BrandLive {
  color: $color-white-primary;
  background-color: $color-black-primary;
}

.Live {
  background-color: $color-black-primary;
  padding: spacing(4.5) 0;

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(2);
    display: inline-block;
    width: 50%;
    vertical-align: middle;
  }
}

.LiveContainer {
  @include mq($breakpoint-desktop) {
    max-width: 700px;
    margin: 0 auto;
  }
}

.Description {
  padding-top: spacing(2);
  @include mq($breakpoint-desktop) {
    padding-top: spacing(3.5);
  }
}

.ImageWrapper {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black-primary, 0.6);
  }

  @include mq($breakpoint-desktop) {
    display: inline-block;
    width: 50%;
    vertical-align: middle;
  }
}

.Pattern {
  top: 0;
  left: -5%;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  opacity: 0.15;

  &,
  &::after,
  &::before {
    content: '';
    position: absolute;
    border: 3px solid $color-white-primary;
    width: 110%;
    height: 0;
    padding-bottom: 110%;
    border-radius: 50%;
  }

  &::before {
    left: -80%;
  }

  &::after {
    right: -80%;
  }

  @include mq($breakpoint-tablet) {
    left: 0;
    &,
    &::after,
    &::before {
      width: 95%;
      padding-bottom: 95%;
    }
  }
}

.ImageContent {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-x: hidden;
  z-index: 1;
  letter-spacing: $font-tracking-5;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: spacing(2.5);

  @include mq($breakpoint-desktop) {
    padding: spacing(5);
  }
}

.LiveIcon {
  width: 89px;
  height: 89px;

  @include mq($breakpoint-desktop) {
    width: 187px;
    height: 187px;
  }
}

.LiveText {
  margin-right: spacing(10);
}
