
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.PerspectiveCarousel {
  position: relative;
  text-align: center;
  width: 231px;
  height: 175px;

  @include mq($breakpoint-desktop) {
    width: 500px;
    height: 380px;
  }
}

.Item {
  position: absolute;
  transform: scale(1) translate(-50%, 0);
  left: 50%;
  z-index: 1;
  transition: all 0.2s;
  transition-delay: 0.1s;
  width: 124px;
  box-shadow: 0 4px 28px 8px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  overflow: hidden;

  @include mq($breakpoint-desktop) {
    width: 270px;
  }
}

.ItemSolo {
  border-radius: unset;

  img {
    @include mq($breakpoint-desktop) {
      display: flex;
      margin-left: auto;
    }
    @include mq($breakpoint-desktopMedium) {
      display: unset;
      margin-left: unset;
    }
  }
}

.left {
  left: 0;
  transition-delay: 0s;
  transform: scale(0.8) translate(0, 0);
  transform-origin: 0 50%;
  z-index: 0;
}

.right {
  left: 100%;
  transition-delay: 0.05s;
  transform: scale(0.8) translate(-100%, 0);
  transform-origin: 0% 50%;
  z-index: 0;
}

.Navigation {
  margin-top: spacing(3);
  position: relative;
  z-index: 1;
}

.Texture {
  position: absolute;
  top: 50%;
  left: 85%;
  transform: translate(-50%, -50%) scale(1.87);
  z-index: 0;
  opacity: 0.06;
  display: block;

  @include mq($max: $breakpoint-desktop - 1) {
    display: none;
  }
}
