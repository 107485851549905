
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  padding-bottom: spacing(1.5);
  margin-bottom: spacing(1.5);
  border-bottom: 1px solid $color-misc-divider;

  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(4);
    margin-bottom: spacing(4);
  }
}

.Description {
  font-size: $font-body-16;
  line-height: $font-height-120;
  @include mq($breakpoint-desktop) {
    font-size: $font-body-20;
    margin-bottom: spacing(8);
  }
}
