
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Tweet {
  background-color: $color-misc-twitter;
  color: $color-white-primary;
  padding: spacing(2);
  margin: 0 spacing(-2);

  @include mq($breakpoint-desktop) {
    padding: spacing(4.75) spacing(4) spacing(3);
    margin: 0;
    border-radius: 4px;
  }
}

.TweetContent {
  padding-bottom: spacing(8);
  font-size: $font-body-20;
  font-weight: $font-weight-medium;
  position: relative;

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }

  * {
    display: inline;
  }

  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(3.5);
  }
}

.Retweet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-heading-eight;
  font-weight: $font-weight-medium;

  a {
    text-decoration: none;
    color: $color-white-primary;
  }
}

.IconArrow {
  margin-left: spacing(0.8);
}
