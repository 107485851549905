
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AboutBrand {
  background-color: $color-black-primary;
  padding-top: spacing(5);
  padding-bottom: spacing(2);
  overflow: hidden;
  border-bottom: 3px solid var(--color-theme--secondary);

  @include mq($breakpoint-tablet) {
    padding-top: spacing(8);
    padding-bottom: spacing(8);
    min-height: 582px;
  }
}

.AboutBrandInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    text-align: left;
  }
}

.Content {
  color: $color-white-primary;

  @include mq($breakpoint-tablet) {
    margin-right: spacing(5);
  }

  @include mq($breakpoint-desktopMedium) {
    margin-right: spacing(10);
  }
}

.Description {
  margin-top: spacing(5);
}

.Logo {
  max-width: 228px;
  max-height: 60px;

  @include mq($breakpoint-tablet) {
    max-width: 320px;
  }

  @include mq($breakpoint-desktop) {
    max-width: 460px;
  }
}

.Carousel {
  margin-top: spacing(8);

  @include mq($breakpoint-tablet) {
    margin-top: unset;
  }
}
