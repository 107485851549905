
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AdvertiseFeatures {
  padding: spacing(5) 0 0;
  background-color: $color-black-secondary;
  color: $color-white-primary;
  @include mq($breakpoint-desktop) {
    padding: spacing(11.5) 0 spacing(5);
  }
}

.FeatureGrid {
  .Feature {
    padding-bottom: spacing(6.5);
  }

  @include mq($breakpoint-tabletLandscape + 40) {
    // 840
    display: flex;
    flex-wrap: wrap;
    margin-left: spacing(-5);

    .Feature {
      flex: 1 1 33%;
      padding-left: spacing(5);
    }
  }

  @include mq($breakpoint-desktopMedium) {
    margin-left: spacing(-10.5);

    .Feature {
      padding-left: spacing(10.5);
      padding-bottom: spacing(11.5);
    }
  }
}

.FeatureTitle {
  display: flex;
  align-items: flex-start;
  font-size: $font-heading-six;
  font-weight: $font-weight-bold;
  line-height: $font-height-120;
  padding-bottom: spacing(2);

  @include mq($breakpoint-desktopMedium) {
    padding-bottom: spacing(3.5);
    font-size: $font-heading-four;
  }
}

.TitleIcon {
  margin-right: spacing(1.5);

  svg path {
    fill: var(--color-theme--secondary);
  }

  @include mq($breakpoint-desktop) {
    margin-right: spacing(2);
    width: 31px !important;
    height: 28px !important;
    line-height: 28px !important;
  }
}
