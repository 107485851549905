
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .CaptionedMedia {}

// .CaptionedMediaBody {}

.CaptionedMediaCaption {
  padding: spacing(1) 0;
}

.Caption {
  padding: spacing(1.5) spacing(2);
  background-color: $color-misc-caption;
  color: $color-grey-primary;
  @include mq($breakpoint-desktop) {
    padding: spacing(2) 0 0 0;
    background-color: transparent;
  }
}
