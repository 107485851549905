
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Header {
  border-bottom: 2px solid $color-misc-divider;
  padding-bottom: spacing(1.5);

  @include mq($max: $breakpoint-desktop - 1) {
    display: none;
  }
}

.muted {
  color: $color-grey-secondary;
}

.InfoBlock {
  padding: spacing(2) 0;
  border-bottom: 2px solid $color-misc-divider;

  @include mq($breakpoint-desktop) {
    border-width: 1px;
  }

  p {
    color: $color-grey-primary;
    margin-bottom: spacing(0.5);
  }

  &:last-child {
    border-bottom: 0;

    @include mq($breakpoint-desktop) {
      padding-bottom: 0;
    }
  }
}

.InfoBlockHeading {
  margin-bottom: spacing(2);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(1);
  }
}

.CTA {
  color: var(--color-theme--secondary);
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-grey-secondary;

    path {
      fill: $color-grey-secondary;
    }
  }

  @include mq($max: $breakpoint-desktop - 1) {
    display: block;
    margin-top: spacing(2);
  }
}

.Icon {
  margin-bottom: 2px;
  margin-left: spacing(1);
  path {
    fill: var(--color-theme--secondary);
  }
}

.EventRegistrationButton {
  margin-top: 20px;
}
