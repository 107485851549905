
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventAgenda {
  position: relative;
  color: $color-black-primary;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  background-color: rgba(white, 0.95);

  @include mq($breakpoint-tablet) {
    padding-top: spacing(10);
    padding-bottom: spacing(10);
  }

  .Container {
    z-index: 1;
  }
}

.Top {
  display: flex;
  flex-direction: column;
  gap: spacing(2);

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.ButtonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(1);

  .Button {
    background-color: $color-white-primary;
    border: 1px solid var(--color-theme--event);
    color: var(--color-theme--event);

    &.active {
      background-color: var(--color-theme--event);
      color: $color-white-primary;
      border: none;
    }
  }
}

.EventAgendaImage {
  z-index: -1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Timeline {
  width: 100%;
  overflow-x: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 14px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-theme--primary);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-theme--event);
  }
}

.StageLabels {
  z-index: 1;
  position: sticky;
  left: 100px;
  transform: translateX(-100px);
  bottom: 0;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: spacing(0.5);
  row-gap: spacing(2);
  height: 0;

  .StageLabelsInner {
    width: 100%;
    height: 100px;

    .StageLabel {
      position: relative;
      height: 100%;
      display: flex;
      // justify-content: center;
      align-items: center;
      backdrop-filter: blur(4px);
      padding-top: spacing(2);
      padding-bottom: spacing(2);

      .StageLabelInner {
        border-left: solid spacing(0.5) var(--color-theme--event);
        color: $color-black-primary;
        padding-left: spacing(2);
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

.ScrollArea {
  position: relative;
  padding-left: 100px;

  .ScrollAreaInner {
    display: grid;
    gap: spacing(1);
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    position: relative;
  }
}

.TimeLabel {
  border-left: solid #718096 1px;
  position: relative;
  max-height: 10px;

  .TimeLabelValue {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -100%);
    color: #718096;
  }
}

.Box {
  background-color: white;
}

.TimelineRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: spacing(0.5);
}

.TimelineBox {
  background-color: $color-black-primary;
  color: $color-white-primary;
  transition: all 0.1s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: spacing(0.5);
  padding-left: spacing(2);
  padding-right: spacing(2);
  text-align: left;

  &:hover {
    outline: spacing(0.5) var(--color-theme--event) solid;
    cursor: pointer;
  }

  &.live {
    background: var(--color-theme--event) !important;
  }
}

.TimelineBoxSpeakers {
  &::before {
    content: '|';
    font-weight: $font-weight-bold;
    color: $color-misc-green;
    margin-right: spacing(1);
  }
}

.CurrentTimeIndicator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--color-theme--event);
  z-index: 10; /* Ensure it is above other elements */
}

.EventAgenda.dark {
  background-color: rgba(black, 0.9);
  color: $color-white-primary;

  .StageLabelInner {
    color: $color-white-primary !important;
  }

  .TimelineBox {
    background-color: $color-white-primary;
    color: $color-black-primary;
  }
}
