
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.inverse {
  background-color: $color-black-primary;
  position: relative;
  padding-top: spacing(3);

  & + & {
    padding-top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: spacing(-4);
    height: spacing(4);
    background-color: $color-black-primary;
  }
}

.tight {
  margin-bottom: spacing(-4);
}

.full:first-child {
  margin-top: spacing(-4);
}
