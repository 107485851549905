
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ArticleTags {
  display: flex;
  flex-wrap: wrap;
}

.Tag {
  font-style: italic;
  margin-bottom: spacing(1);
  &:not(:last-child) {
    margin-right: spacing(1);
  }
}
