
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Html {
  iframe[src*='youtube'] {
    width: 320px;
    height: 180px;
    max-width: 100%;

    @include mq($breakpoint-tablet) {
      width: 480px;
      height: 270px;
    }

    @include mq($breakpoint-desktop) {
      width: 640px;
      height: 360px;
    }
  }
}
