
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.KeyFacts {
  padding: spacing(2.5) 0;
  border-top: 2px solid $color-misc-divider;
  border-bottom: 2px solid $color-misc-divider;
  @include mq($breakpoint-desktop) {
    padding: spacing(3.5) 0;
  }
}

.Title {
  text-transform: uppercase;
  color: $color-misc-red;
  padding-bottom: spacing(2.8);
  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(3.5);
  }
}

.List {
  padding-left: spacing(3.5);
  & > li + li {
    margin-top: spacing(2.5);
    @include mq($breakpoint-desktop) {
      margin-top: spacing(3.5);
    }
  }

  li {
    list-style: disc outside;
  }
}
