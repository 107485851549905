
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.VideoGrid {
  padding: spacing(1.5) 0 spacing(3);
  background-color: $color-black-primary;
  overflow: hidden;

  @include mq($breakpoint-desktop) {
    padding: spacing(2.5) 0 spacing(3);
  }

  &.inverse {
    margin-top: spacing(-3);
    @include mq($breakpoint-desktop) {
      padding-top: spacing(4.5);
    }
  }
}

.Title {
  padding-bottom: spacing(3);
  position: relative;
  z-index: 2;

  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(2);
  }
}

.HeroCard {
  margin-bottom: spacing(3);

  @include mq($max: $breakpoint-desktop - 1) {
    position: relative;
    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      top: spacing(-20);
      left: spacing(-2);
      right: spacing(-2);
      bottom: 0;
      background: $color-black-secondary;
    }
  }
}
