
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ArticlePartners {
  border-top: 2px solid $color-misc-divider;
  border-bottom: 2px solid $color-misc-divider;
  padding: spacing(2) 0 spacing(3);

  @include mq($breakpoint-desktop) {
    border-bottom: 0;
  }
}

.Title {
  margin-bottom: spacing(2.5);
  text-transform: uppercase;
  color: $color-misc-red;

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2.5);
  }
}

.Partner {
  margin-bottom: spacing(2.5);
  @include mq($max: $breakpoint-desktop - 1) {
    padding-bottom: spacing(2.5);

    div:last-child > & {
      padding-bottom: 0;
    }
  }
}

.PartnerName {
  margin-bottom: spacing(2);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2.5);
  }
}

.Button {
  span > span:last-child {
    margin-left: spacing(1);
    margin-bottom: 3px;
  }
  @include mq($max: $breakpoint-desktop - 1) {
    width: 100%;

    span {
      justify-content: center !important;
    }
  }
}
