
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ProfileTag {
  display: inline-flex;
  color: $color-white-primary;
  border-radius: spacing(0.5);
  overflow: hidden;
}

.LabelWrapper {
  position: relative;
  background-color: #4d4c4c;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 spacing(1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 75%,
      rgba(0, 0, 0, 0.5) 100%
    );
    pointer-events: none; // Ensure the overlay does not interfere with interactions
  }
}

.Value {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: var(--color-theme--secondary);
  padding: 0 spacing(1);

  .inner {
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 75%,
      rgba(0, 0, 0, 0.4) 100%
    );
    pointer-events: none; // Ensure the overlay does not interfere with interactions
  }
}
