
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CompanyMagazineCTA {
  @include mq($breakpoint-desktop) {
    max-width: unset !important;
  }

  .CompanyMagazineCTATitle {
    font-size: 1rem !important;
  }

  .CompanyMagazineCTAButton {
    padding: spacing(0.5) spacing(1) !important;
    * {
      font-size: 0.75rem !important;
    }
  }
}

.CompanyLink {
  @include mq($breakpoint-desktop) {
    max-width: unset !important;
  }
}

.CompanyAdvert {
  max-width: 100%;
  img {
    width: 100% !important;
    object-fit: contain !important;
    object-position: top !important;
  }
}

.CompanySimpleList {
  margin-top: spacing(2);
}
