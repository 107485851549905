
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SimpleListTitle {
  padding-bottom: spacing(2);
  border-bottom: 2px solid $color-misc-divider;
  text-transform: uppercase;
}

.SimpleListTitleSecondary {
  color: $color-grey-secondary;
}

.SimpleListLink {
  text-decoration: none;
  padding: spacing(2) 0;
  color: $color-black-primary;
  display: block;
  border-bottom: 1px solid $color-misc-divider;

  @include mq($breakpoint-desktop) {
    li:first-child & {
      padding-top: 0;
    }
    li:last-child & {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &:hover,
  &:focus {
    color: var(--color-theme--secondary);
  }

  &.inverse {
    color: $color-white-primary;

    &:hover,
    &:focus {
      color: var(--color-theme--secondary);
    }
  }
}

.SimpleListTags {
  margin-top: spacing(0.75);
}
