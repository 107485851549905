
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ArticleDownload {
  &:hover,
  &:focus {
    svg path {
      fill: $color-white-primary;
    }
  }
}

.Label {
  margin-right: spacing(1);
}
