
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MagazineIssueModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-black-primary, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
  height: 100%;
  padding: spacing(2.5) 0;
}

.Inner {
  position: relative;
  border-top: 3px solid var(--color-theme--secondary);
  background-color: $color-black-primary;
  width: 100%;
  padding: spacing(1.5) spacing(2.5) spacing(4);
  display: flex;
  flex-direction: column;

  @include mq($breakpoint-desktop) {
    border-width: 4px;
    padding: 0;
  }

  .compact & {
    max-width: 980px;
    padding: spacing(9) spacing(4) spacing(7);
  }
}

.Button {
  position: absolute;
  top: spacing(6);
  right: spacing(3);
  background-color: var(--color-theme--secondary);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);

  &:hover,
  &:focus {
    background-color: $color-grey-secondary;
  }

  .compact & {
    top: spacing(3);
  }
}
