
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BlockquoteWrapper {
  background-color: $color-white-secondary;
  display: flex;
  min-height: 100%;
  padding: spacing(4.5) spacing(2.5) spacing(2.5) spacing(2.5);
  border-left: 6px solid var(--color-theme--secondary);

  &.inverse {
    background-color: $color-black-secondary;
    color: $color-white-primary;
  }
}

.Blockquote {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: spacing(4);
}

.BlockquoteQuote {
  z-index: 1;
  position: relative;
  line-height: $font-height-140;
  color: #555;
}

.BlockQuoteFooter {
  display: flex;
  justify-content: space-between;
}

.BlockquoteAttribution {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  line-height: $font-height-160;
}

.BlockquoteQuotationMarks {
  position: absolute;
  top: spacing(-2);
  left: spacing(-1);
  opacity: 0.3;
  color: var(--color-theme--secondary);

  .inverse & {
    opacity: 0.6;
  }
}
