
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ArticleShare {
  padding-bottom: spacing(2);

  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(0);
  }
}

.Title {
  margin-bottom: spacing(1);
  text-transform: uppercase;
  color: $color-misc-red;

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(0.5);
  }
}

.Share {
  display: flex;
  align-items: center;

  & > .ShareItem + .ShareItem {
    margin-left: spacing(5);
  }

  @include mq($breakpoint-desktop) {
    & > .ShareItem + .ShareItem {
      margin-left: spacing(3.5);
    }
  }

  @include mq($max: $breakpoint-desktop - 1) {
    justify-content: space-between;
  }
}

.ShareItem {
  display: block;
  color: $color-black-primary;
  svg {
    fill: $color-black-primary;
  }

  &:hover,
  &:focus {
    svg,
    path {
      fill: var(--color-theme--secondary);
    }
  }
}
