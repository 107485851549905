
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Wrapper {
  background-color: $color-misc-magazine-viewer;
  width: 100%;
  height: 140vw; // Apect ratio of magazine page (1.4) multiuplied by the full viewport width
  max-height: calc(100vh - 90px);

  @include mq($breakpoint-desktop) {
    height: unset;
    max-height: unset;
  }
}

.IssueMagazineViewer {
  display: block;
  border: 0;
  width: 100%;
  height: 100%;

  @include mq($breakpoint-desktop) {
    height: 100vh;
    max-height: calc(100vh - 190px);
  }

  @include mq($breakpoint-desktopLarge) {
    max-height: calc(100vh - 280px);
  }

  &.fullscreen {
    max-height: 100vh;
  }
}
