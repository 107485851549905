
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.HeadingWrapper {
  margin-bottom: spacing(8);
}

.ButtonInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Icon {
  margin-left: spacing(2);
}

.ButtonLabel {
  line-height: 1;
}

.TextButton {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
