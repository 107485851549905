
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonWrapper {
  border-top: 2px solid $color-misc-divider;
  padding-top: spacing(4);
}

.ArticleEventButton {
  color: $color-white-primary;
  background-color: var(--color-theme--secondary);

  &:hover,
  &:focus {
    svg path {
      fill: $color-white-primary;
    }
  }
}
