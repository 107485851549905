
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.clipped {
  height: 180vh;
  overflow: hidden;
  position: relative;
}

.PaywallBarrierContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
