
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AboutBizClik {
  padding: spacing(3.5) 0 spacing(6);
  background-color: $color-black-secondary;
  color: $color-white-primary;

  @include mq($breakpoint-tabletLandscape) {
    padding: spacing(10) 0 spacing(8.5);
  }
}

.Container {
  @include mq($breakpoint-tabletLandscape) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.Content {
  @include mq($breakpoint-tabletLandscape) {
    max-width: 720px;
    margin-right: spacing(10);
  }
}

.Title {
  margin-bottom: spacing(1.5);
  // max-width: 530px;
  @include mq($breakpoint-tabletLandscape) {
    margin-bottom: spacing(4);
  }
}

.Services {
  padding-top: spacing(1.5);

  & > * + * {
    margin-top: spacing(2.5);
  }

  .BMGLogo {
    margin-left: -4px;
  }

  @include mq($breakpoint-tabletLandscape) {
    padding-top: 0;

    & > * + * {
      margin-top: spacing(1);
    }

    .Logo,
    .Logo svg {
      height: 96px !important;
      line-height: 96px !important;
    }

    .BMGLogo {
      margin-top: spacing(7.5);
      margin-left: -7px;
    }

    .BMGLogo,
    .BMGLogo svg {
      height: 112px !important;
      line-height: 112px !important;
    }
  }
}

.Logo,
.BMGLogo {
  display: block !important;
}

.Logo {
  &.business svg path {
    fill: $color-service-business;
  }
  &.events svg path {
    fill: $color-service-connect;
  }
  &.lifestyle svg path {
    fill: $color-service-lifestyle;
  }
  &.intelligence svg path {
    fill: $color-service-intelligence;
  }
  &.studio svg path {
    fill: $color-service-studio;
  }
  &.networks svg path {
    fill: $color-service-networks;
  }
}
