
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .ProfileHeader {}

.Image {
  margin-left: spacing(-2);
  margin-right: spacing(-2);
  margin-bottom: spacing(2);
  @include mq($breakpoint-desktop) {
    max-width: 220px;
    margin-left: spacing(4);
    margin-right: 0;
    margin-bottom: 0;
  }
}

.Content {
  padding: spacing(0.5) 0 0;
  @include mq($breakpoint-desktop) {
    padding: spacing(2);
  }
}

.Heading {
  margin-bottom: spacing(2);
  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(1);
  }
}

.SubHeading {
  color: var(--color-theme--secondary);
  margin-bottom: spacing(2);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2.5);
  }
}

.Description {
  max-width: 600px;
}

.Links {
  margin-top: spacing(2.5);
}
