
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AdvertiseHeader {
  position: relative;
  text-align: center;
  color: $color-white-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 287px;

  @include mq($breakpoint-tablet) {
    height: 580px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-black-primary, 0.5);
  }
}

.HeaderImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  &.headerDesktop {
    display: none;
    @include mq($breakpoint-tablet) {
      display: block;
    }
  }

  &.headerMobile {
    display: block;
    @include mq($breakpoint-tablet) {
      display: none;
    }
  }
}

.Content {
  max-width: 228px;
  z-index: 1;
  @include mq($breakpoint-tablet) {
    max-width: 600px;
  }
}

.Title {
  margin-bottom: spacing(0.5);

  @include mq($breakpoint-tablet) {
    margin-bottom: spacing(1.5);
  }
}

.Logo {
  max-height: 30px;
  display: block;
  margin: 0 auto spacing(3);

  @include mq($breakpoint-tablet) {
    margin-bottom: spacing(6);
    max-height: 60px;
  }
}

.CTA {
  @include mq($breakpoint-tablet) {
    min-width: 250px;
  }
}
