
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.PaywallBarrier {
  position: relative;
  background-color: $color-white-primary;
}

.Overlay {
  position: absolute;
  height: 273px;
  top: -273px;
  width: 100%;
  background: linear-gradient(
    rgba($color-black-primary, 0),
    $color-white-primary
  );
}

.Wrapper {
  border-top: 4px solid var(--color-theme--secondary);
}

.PaywallBarrierContainer {
  text-align: center;
  padding-top: spacing(5.25);
  padding-bottom: spacing(5.25);
}

.Copy {
  display: none;
  color: $color-grey-primary;
  max-width: 540px;
  margin: spacing(1.5) auto 0;

  @include mq($breakpoint-tablet) {
    display: block;
  }
}

.Fields {
  margin-top: spacing(3);

  & > * + * {
    margin-top: spacing(3.5);
  }

  label {
    padding-bottom: spacing(1);
    display: block;
    font-weight: 500;
    font-size: 16px;

    @include mq($breakpoint-desktop) {
      font-size: 12px;
    }
  }

  @include mq($breakpoint-tablet) {
    display: flex;
    margin-top: spacing(5);

    & > * + * {
      margin-left: spacing(4.25);
      margin-top: 0;
    }
  }
}

button.Submit {
  display: block;
  width: 100%;
  margin-top: spacing(5);
  padding-top: spacing(2.5);
  padding-bottom: spacing(2.5);
  @include mq($breakpoint-tablet) {
    display: inline-block;
    min-width: 330px;
    width: auto;
  }
}
