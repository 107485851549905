
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FixedWidthColumn {
  margin: spacing(4) auto 0;

  @include mq($breakpoint-desktop) {
    margin: 0;
    // max-width: calc(50% - 16px);
    width: 300px;
    flex-shrink: 0;
    margin-left: spacing(3);
    order: 1;
  }
}

.show-first {
  @include mq($breakpoint-desktop) {
    order: -1;
    margin-left: 0;
    margin-right: spacing(3);
  }
}
