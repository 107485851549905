
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BrandNumbers {
  background-color: $color-black-primary;
  color: $color-white-primary;
  padding: spacing(4.5) 0 spacing(1.5);
  @include mq($breakpoint-desktop) {
    padding: spacing(10) 0 spacing(6.5);
  }
}

.BrandNumbersContainer {
  max-width: 980px;
}

.Title {
  text-align: center;
  margin-bottom: spacing(3.5);
  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(8);
  }
}

.Numbers {
  color: $color-white-primary;

  @include mq($breakpoint-tabletLandscape) {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    & > .NumberWrapper {
      &:not(:last-child) {
        margin-right: spacing(12.5);
      }
    }
  }
}

.NumberWrapper {
  text-align: center;
  padding-bottom: spacing(3.5);

  @include mq($breakpoint-tabletLandscape) {
    min-width: 180px;
    text-align: left;
  }

  @include mq($breakpoint-desktop) {
    min-width: 230px;
  }
}

.Number {
  position: relative;
  margin-bottom: spacing(1.5);
  padding-bottom: spacing(1);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 2px;
    border-bottom: 2px solid $color-misc-divider;
    width: 100px;
  }

  @include mq($breakpoint-tabletLandscape) {
    &::after {
      width: 100%;
      right: unset;
    }
  }

  @include mq($breakpoint-desktop) {
    padding-bottom: 0;
  }
}
