
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Breadcrumbs {
  display: flex;
  flex-wrap: wrap;
}

.Crumb {
  display: flex;
  align-items: center;
  position: relative;
  color: $color-grey-primary;

  &:not(:last-child) {
    margin-right: spacing(1);
    &::after {
      content: '';
      width: 4px;
      height: 4px;
      top: -1px;
      border-radius: 50%;
      display: block;
      position: relative;
      background: $color-grey-primary;
      margin-left: spacing(1);
    }
  }
}

.last {
  .Crumb {
    &:last-child {
      color: var(--color-theme--secondary);
      &::after {
        background: var(--color-theme--secondary);
      }
    }
  }
}

.all-but-first {
  .Crumb {
    &:not(:first-child) {
      color: var(--color-theme--secondary);
      &::after {
        background: var(--color-theme--secondary);
      }
    }
  }
}
