
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.Wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);

  &.disabled {
    pointer-events: none;
  }

  .PlayIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: white;
  }
}

.Placeholder {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  &.hide {
    animation: fadeOut 0.5s ease-out 0.5s forwards;
    pointer-events: none;
  }
}
