
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AdvertiseTestimonial {
  color: $color-white-primary;
  background: $color-black-primary;

  // & > div {
  //   position: relative;
  //   flex: 1 1 50%;
  // }

  @include mq($breakpoint-desktop) {
    flex-direction: row;
  }
}

.About {
  background-color: $color-black-primary;
  padding: spacing(4.5) 0;
  @include mq($breakpoint-desktop) {
    padding: spacing(3) 0;
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }
}

.AboutContainer {
  @include mq($breakpoint-desktop) {
    margin: 0 auto;
    max-width: 700px;
  }
}

.Description {
  padding-top: spacing(2);
  @include mq($breakpoint-desktop) {
    padding-top: spacing(3.5);
  }
}

.Quote {
  display: block;
  position: relative;
  @include mq($breakpoint-desktop) {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }
}

.Blockquote {
  position: absolute;
  background-color: rgba($color-misc-overlay, 0.7);
  padding: spacing(6) 0 spacing(3.375);
  z-index: 1;
  width: 100%;
  bottom: 0;
}

.BlockquoteContainer {
  position: relative;

  p {
    position: relative;
  }

  @include mq($breakpoint-desktop) {
    padding-left: spacing(5);
    margin-right: auto;
    max-width: 700px;
  }
}

.BlockquoteQuotationMarks {
  position: absolute;
  top: spacing(-8);
  left: 0;
  z-index: -1;

  svg {
    fill: var(--color-theme--secondary);
  }

  @include mq($breakpoint-desktop) {
    top: spacing(-2.5);
    left: spacing(-1.5);
  }
}

.BlockquoteFooter {
  padding-top: spacing(2.75);

  img {
    max-width: 70px;
    margin: 0 0 spacing(1.5) 0;
  }

  @include mq($breakpoint-desktop) {
    display: flex;
    align-items: flex-start;
    img {
      max-width: 105px;
      margin: 0 spacing(4) 0 0;
    }
  }
}

.Attribution,
.SecondaryAttribution {
  line-height: 1;
}

.SecondaryAttribution {
  padding-top: spacing(1);
  color: var(--color-theme--secondary);
}
